/* eslint-disable import/no-named-as-default-member */

const endpoints = {
  campaign: {
    campaign: 'campaign',
    root: 'campaigns',
    list: 'campaigns',
    activity: 'activity',
    leads: 'leads',
    status: 'toggleStatus',
    uploadContacts: 'campaign/upload_list_action',
    create: 'campaign/create',
    getSettings: 'get-campaign-setting',
    updateGeneralSettings: 'campaign-setting/general',
    updateEmailMessageSettings: 'campaign-setting/email-settings',
    updateEmailSendingCadenceSettings: 'campaign-setting/email-sending-cadence',
    updateLandingPageSettings: 'campaign-setting/landing-page',
    landingPage: 'default-landing',
    searchPlace: 'campaign/searchPlace',
    getPlace: 'campaign/getPlaceDetail',
    updateSMSSendingCadence: 'campaign-setting/sms-sending-cadence',
    updateSMSSettings: 'campaign-setting/sms-settings',
    sendTestMessage: 'send_test_message',
    smsLandingPage: 'sms-default-landing',
    // feedback: 'feedback',
    feedback: 'sms_feedback',
    oneTimeBlast: 'campaign-setting/single-blast-settings',
    getGoogleLocationMetrics: 'location/getGoogleLocationMetrics',
    clearCache: 'campaignCacheClear',
    facebookPages: 'facebook-pages/location_id',
    removeFacebook: 'remove-facebook-mapping/location_id',
    facebookPosts: 'facebook-posts',
    addFacebookPage: 'add-facebook-page/location_id',
  },
  partner: {
    root: 'partners',
    add: 'partner/create',
    delete: 'partners',
    listCampaign: 'partner',
    getPartnerSettings: 'partners_setting',
    updateGeneralSettings: 'partners_setting/general',
    updateEmailMessageSettings: 'partners_setting/email/default',
    updateEmailCadenceSettings: 'partners_setting/cadence/default',
    updateLandingPageSettings: 'partners_setting/page/default',
    sendTestEmail: 'send_test_email',
    updateNotificationSettings:
      'partners_setting/save_notification_email_settings',
    sendNotificationTestEmail: 'send_test_notification_email',
    getAggregateReport: 'get-partner-aggregate-report',
    getBusinessAggregateReport: 'get-business-aggregate-report',
    getDefaultLandingPage: 'campaign-default-landing-page',
    partnerCampaignTypes: 'partners/campaign_types',
    updateSMSCadenceSettings: 'partners_setting/sms/cadence/default',
    updateSMSSettings: 'partners_setting/sms/default',
    partnerLocalSEOReport: 'get-partner-local-seo-report',
  },
  contact: {
    unsubscribe: 'contacts/unsubscribe',
    delete: 'contacts/delete',
    manualSend: 'contacts/manual_send',
    addContact: 'add-contact',
    update: 'campaigns/update-contact',
    manualSendSMS: 'contacts/manual_send_sms',
  },
  business: {
    root: 'business',
    create: '/business/create',
    businessLocalSEOReport: 'get-business-local-seo-report',
  },
  admin: {
    industry: {
      root: 'admin-industry',
      create: 'admin-industry/create',
    },
    campaignType: {
      root: 'admin-campaign-type',
      create: 'admin-campaign-type/create',
    },
    category: {
      root: 'admin-category',
      create: 'admin-category/create',
    },
    settings: {
      getAdminInfo: 'get-admin-info',
      root: 'admin_setting',
      updateNotification: 'admin_setting/save_notification_email_settings',
      sendTestNotificationEmail: 'send_admin_test_notification_email',
      emailMessage: 'admin_setting',
      updateGeneralSettings: 'admin_setting/general',
      updateEmailSendingCadenceSettings: 'admin_setting/cadence/default',
      sendTestEmail: 'admin_send_test_email',
      updateLandingPageSettings: 'admin_setting/page/default',
      updateSMSSendingCadence: 'admin_setting/sms-sending-cadence/default',
      updateSMSSettings: 'admin_setting/sms-sending',
      syncToAllPartners: 'admin_setting/sync-admin-settings-to-partner',
    },
  },
  auth: {
    login: '/login',
    register: '/register',
    forgetPassword: '/forget-password',
    resetPassword: 'reset-password',
  },
  manageUser: {
    list: 'sub-user-list',
    add: 'sub-user-add',
    update: 'sub-user-update',
    delete: 'sub-user-delete',
    filter: 'entity-list-by-user',
    updatePassword: 'update-password',
    updateUserPassword: 'update-user-password',
  },
  location: {
    root: 'location',
    googleLocationPost: 'getGoogleLocationPostList',
    callLog: 'call-logs',
  },
  googlePost: {
    delete: 'location/deleteGoogleLocationPost',
  },
  industry: {
    add: 'add-industry',
    update: 'update-industry',
    delete: 'industry',
  },
  campaignType: {
    add: 'save-campaign-type',
    update: 'update-campaign-type',
    delete: 'delete-campaign-type/id',
  },
  category: {
    add: 'save-campaign-category',
    update: 'update-campaign-category',
    delete: 'delete-campaign-category/id',
  },
  actionItem: {
    update: 'business/action_items',
  },
  connectToFacebook: 'auth/facebook',
  replyFacebookMessage: 'reply-facebook-message',
  postComment: 'send-facebook-post-comment',
};

export default endpoints;
